import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'router'
import { Menu, Layout } from 'antd'
import classNames from 'classnames'
import store from 'store'
import { find } from 'lodash'
// import { history } from 'index'
import SimpleBar from 'simplebar-react'

import styles from './style.module.scss'

const mapStateToProps = ({ menu, settings, user, dispatch }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isSidebarOpen: settings.isSidebarOpen,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  role: user.role,
  dispatch,
})

class MenuLeft extends React.Component {
  // const MenuLeft = ({
  //   dispatch,
  //   menuData = [],
  //   location: { pathname },
  //   isMenuCollapsed,
  //   isSidebarOpen,
  //   isMobileView,
  //   isMenuUnfixed,
  //   isMenuShadow,
  //   leftMenuWidth,
  //   menuColor,
  //   logo,
  //   role,
  // }) => {

  state = {
    selectedKeys: store.get('app.menu.selectedKeys') || ['dashboardnine'],
    openedKeys: store.get('app.menu.openedKeys') || [],
  }

  menuKeyMap = []

  componentDidMount() {
    this.applySelectedKeys()
  }

  componentDidUpdate(prevProps /* , prevState */) {
    const { location, menuData } = this.props
    if (prevProps.menuData !== menuData || prevProps.location.pathname !== location.pathname) {
      this.applySelectedKeys()
    }
  }

  applySelectedKeys = () => {
    const {
      location: { pathname },
      menuData,
    } = this.props

    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    // const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    const menuItems = flattenItems(menuData, 'children')
    let selectedItem = find(menuItems, (menu) => {
      if (menu.url === pathname) {
        return true
      }
      return false
    })

    if (selectedItem == null) {
      let curMenu = null
      let mCnt = 0
      const pathArr = pathname.split('/')
      menuItems.forEach((menu) => {
        if (menu.url != null) {
          const urlArr = menu.url.split('/')
          const len = Math.min(urlArr.length, pathArr.length)
          // 0번째는 전부 ''이므로 비교할 필요없음.
          for (let i = 1; i < len; i += 1) {
            if (pathArr[i] === urlArr[i]) {
              if (mCnt < i + 1) {
                mCnt = i + 1
                curMenu = menu
              }
            } else {
              break
            }
          }
        }
      })

      if (curMenu != null) {
        selectedItem = curMenu
      }
    }

    const keys = selectedItem ? [selectedItem.key] : []
    store.set('app.menu.selectedKeys', keys)

    this.setState({
      selectedKeys: keys,
    })
  }

  onCollapse = (value, type) => {
    const { dispatch, isMenuCollapsed } = this.props

    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    this.setState({
      openedKeys: [],
    })
  }

  onOpenChange = (keys) => {
    store.set('app.menu.openedKeys', keys)
    this.setState({
      openedKeys: keys,
    })
  }

  handleClick = (e) => {
    const { dispatch, isSidebarOpen, isMobileView, history } = this.props
    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSidebarOpen',
          value: !isSidebarOpen,
        },
      })
      return
    }

    this.setState({
      selectedKeys: [e.key],
    })

    if (this.menuKeyMap[e.key] != null) {
      if (this.menuKeyMap[e.key].url != null) {
        history.push(this.menuKeyMap[e.key].url)
      }
    }

    if (isMobileView === true) {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMobileMenuOpen',
          value: false,
        },
      })
    }
  }

  generateMenuItems = () => {
    const { menuData, role } = this.props

    this.menuKeyMap = {}

    const generateItem = (item) => {
      const { key, title, icon, disabled, count } = item
      const titleText = title

      if (item.key != null) {
        this.menuKeyMap[item.key] = item
      }

      if (item.category) {
        return {
          type: 'group',
          label: titleText,
          key: Math.random(),
        }
      }

      if (item.divider === true) {
        return {
          type: 'divider',
        }
      }

      return {
        label: <span className={styles.title}>{titleText}</span>,
        key,
        disabled,
        icon: (
          <React.Fragment>
            {count && <span className="badge badge-success ml-2">{count}</span>}
            {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
          </React.Fragment>
        ),
      }
    }

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const titleText = menuItem.title
          return {
            label: <span className={styles.title}>{titleText}</span>,
            key: menuItem.key,
            icon: (
              <React.Fragment>
                {menuItem.count && (
                  <span className="badge badge-success ml-2">{menuItem.count}</span>
                )}
                {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
              </React.Fragment>
            ),
            children: generateSubmenu(menuItem.children),
          }
        }
        return generateItem(menuItem)
      })

    return menuData.map((menuItem) => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const titleText = menuItem.title
        return {
          label: <span className={styles.title}>{titleText}</span>,
          key: menuItem.key,
          icon: (
            <React.Fragment>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            </React.Fragment>
          ),
          children: generateSubmenu(menuItem.children),
        }
      }
      return generateItem(menuItem)
    })
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const {
      isMobileView,
      leftMenuWidth,
      isMenuCollapsed,
      menuColor,
      isMenuUnfixed,
      isMenuShadow,
      logo,
    } = this.props

    const menuSettings = isMobileView
      ? {
          width: leftMenuWidth,
          collapsible: false,
          collapsed: false,
          onCollapse: this.onCollapse,
        }
      : {
          width: leftMenuWidth,
          collapsible: true,
          collapsed: isMenuCollapsed,
          onCollapse: this.onCollapse,
          breakpoint: 'lg',
        }

    const menuItems = this.generateMenuItems()

    return (
      <Layout.Sider
        {...menuSettings}
        className={classNames(`${styles.menu}`, {
          [styles.white]: menuColor === 'white',
          [styles.gray]: menuColor === 'gray',
          [styles.dark]: menuColor === 'dark',
          [styles.unfixed]: isMenuUnfixed,
          [styles.shadow]: isMenuShadow,
        })}
      >
        <div
          className={styles.menuOuter}
          style={{
            width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
            height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
          }}
        >
          <div className={styles.logoContainer}>
            <div className={styles.logo}>
              <img src="resources/images/logo.svg" className="mr-2" alt="Re:Work Admin" />
              <div className={styles.name}>{logo}</div>
              {/* logo === 'Clean UI Pro' && <div className={styles.descr}>React</div> */}
            </div>
          </div>
          <SimpleBar style={{ height: '100%' }}>
            <Menu
              onClick={this.handleClick}
              selectedKeys={selectedKeys}
              openKeys={openedKeys}
              onOpenChange={this.onOpenChange}
              mode="inline"
              className={styles.navigation}
              inlineIndent="15"
              items={menuItems}
            />
            {/*
            <div className={styles.banner}>
              <p>More components, more style, more themes, and premium support!</p>
              <a
                href="https://themeforest.net/item/clean-ui-react-admin-template/21938700"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-sm btn-success btn-rounded px-3"
              >
                Buy Bundle
              </a>
            </div>
            */}
          </SimpleBar>
        </div>
      </Layout.Sider>
    )
  }
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
